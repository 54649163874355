import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import Tile from '../../components/Tile/Tile'

import SignPosts from '../../svgs/handsholdinghome-circle.svg'
import Condo from '../../svgs/condoBuilding.svg'
import Builder from '../../svgs/builderAvatar.svg'
import HandshakeHome from '../../svgs/handshakeHome.svg'
import Megaphone from '../../svgs/megaphone.svg'
import OBDlogo from '../../svgs/OBDlogo.svg'
import Newsletter from '../../svgs/newsletter.svg'
import Webpage from '../../svgs/webpage.svg'

const NewHomeDashboardPage = () => {
  const intl = useIntl()
  const sizes = [3, 6, 12]
  return (
    <Layout
      view='buyer'
      subheader={<FormattedMessage id='nav.buyerDashB' />}
      header={
        <span className='bold'>
          <FormattedMessage id='nav.dashboard' />
        </span>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'buyerNav.dashboard' })}
      />
      <Container>
        <Row>
          <Col>
            {intl.locale === 'en' && (
              <p style={{ fontStyle: `italic` }}>
                <FormattedMessage id='nav.homebuyersClickThrough' />
              </p>
            )}
            <p style={{ fontStyle: `italic` }}>
              <FormattedMessage id='nav.clickThrough' />
            </p>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              icon={<SignPosts />}
              link={{
                text: <FormattedMessage id='buyerNav.steps' />,
                path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              icon={<Condo />}
              link={{
                text: <FormattedMessage id='buyerNav.preConCondo' />,
                path: '/new-home-buyer/before-buying-a-preconstruction-condo/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              icon={<Builder />}
              link={{
                text: <FormattedMessage id='buyerNav.expectations' />,
                path: '/new-home-buyer/expectations-for-your-builder/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              icon={<HandshakeHome />}
              link={{
                text: <FormattedMessage id='buyerNav.workingWith' />,
                path: '/new-home-buyer/working-with-your-builder/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              icon={<Megaphone />}
              link={{
                text: <FormattedMessage id='buyerNav.complaints' />,
                path: '/licensing-compliance/complaints-process/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              external={true}
              icon={<OBDlogo />}
              link={{
                text: <FormattedMessage id='linkNames.obd' />,
                path: 'http://obd.hcraontario.ca'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              external={true}
              icon={<Newsletter />}
              link={{
                text: <FormattedMessage id='newsletter.headerA' />,
                path: '/new-home-buyer/newsletter/'
              }}
            />
          </Col>

          <Col xl={sizes[0]} md={sizes[1]} sm={sizes[2]}>
            <Tile
              external={true}
              icon={<Webpage />}
              link={{
                // text: <FormattedMessage id='newsletter.headerA' />,
                // path: '/new-home-buyer/newsletter/'
                text: <span lang='en'>HCRA Blog: The Home Front</span>,
                path: '/blog/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NewHomeDashboardPage
